
export const setCheckoutInfo = ( state, data ) => {
    sessionStorage.setItem('checkout-info', JSON.stringify(data))
    state.checkoutInfo = data;
}

export const clearCheckoutInfo = (state) => {
    sessionStorage.removeItem('checkout-info')
    state.checkoutInfo = null;
}

export const setAppointmentToUpdate = (state, data) => {
    sessionStorage.setItem('appoinment-to-update', JSON.stringify(data));
    state.appointmentToUpdate = data;
}

export const clearAppointmentToUpdate = (state) => {    
    sessionStorage.removeItem('appoinment-to-update');
    state.appointmentToUpdate = null;
}