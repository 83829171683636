import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: "admin-invoicing",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
  children: [
    {
      path: "active-sessions",
      name: "admin-active-sessions",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.admin-active-sessions',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-active-sessions" */ "@/modules/admin/invoicing/views/Actives.vue"),
    },
    {
      path: "pending-to-pay",
      name: "admin-invoicing-pending-to-pay",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.admin-invoicing-pending-to-pay',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-invoicing-pending-to-pay" */ "@/modules/admin/invoicing/views/PendingToPay.vue"),
    },
    {
      path: "pending-billing",
      name: "admin-invoicing-pending-billing",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.admin-invoicing-pending-billing',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-invoicing-pending-billing" */ "@/modules/admin/invoicing/views/PendingBilling.vue"),
    },
    {
      path: "billing",
      name: "admin-invoicing-billing",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.admin-invoicing-billing',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-invoicing-billing" */ "@/modules/admin/invoicing/views/Billing.vue"),
    },
    {
      path: "receivable/:userId",
      name: "admin-psychologist-invoicing-receivable",
      // beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            route: {
              name: 'admin-invoicing-pending-to-pay',
              params: {}
            },
            transKey: 'breadcrumbs.back',
            isActive: false
          },
          {
            transKey: 'breadcrumbs.admin-psychologist-invoicing-receivable',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "psychologist-invoicing-receivable" */ "@/modules/psycologists/invoicing/views/Receivable.vue"),
    },
    {
      path: "pending-billing/:userId",
      name: "admin-psychologist-invoicing-pending-billing",
      // beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            route: {
              name: 'admin-invoicing-pending-billing',
              params: {}
            },
            transKey: 'breadcrumbs.back',
            isActive: false
          },
          {
            transKey: 'breadcrumbs.admin-psychologist-invoicing-pending-billing',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "psychologist-invoicing-receivable" */ "@/modules/psycologists/invoicing/views/PendingBilling.vue"),
    },
    {
      path: "billing/:userId",
      name: "admin-psychologist-invoicing-billing",
      // beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            route: {
              name: 'admin-invoicing-billing',
              params: {}
            },
            transKey: 'breadcrumbs.back',
            isActive: false
          },
          {
            transKey: 'breadcrumbs.admin-psychologist-invoicing-billing',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "psychologist-invoicing-receivable" */ "@/modules/psycologists/invoicing/views/Billing.vue"),
    },
  ]
};
