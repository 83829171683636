export const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
} 

export const downloadPdfFile = (data, fileName) => {
    const blob = new Blob([data], {type: "application/pdf"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"
    link.setAttribute('download', `${fileName}.pdf`);
    link.click();
    // document.body.removeChild(link);
} 

export const downloadXmlFile = (data, fileName) => {
    const blob = new Blob([data], {type: "text/xml"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"
    link.setAttribute('download', `${fileName}.xml`);
    link.click();
    // document.body.removeChild(link);
} 
