import { errorHttpMessage } from "@/utils/errorDecodeUtil";
import usersService from '@/services/usersService'

export const getUserById = async ({ commit }, id) => {
    try {
        const { data: result } = await usersService.getUserById(id);
        return result.result;
    } catch (ex) {
          const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            // error_keys: error_http_message.error_keys,
            // data: ex.response.data,
            error: true,
        };
    }
};


export const setUserById = async ({ commit }, {id, data}) => {
    try {
        const { data: result } = await usersService.setUserById({id, data});
        return result.result;
    } catch (ex) {
          const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            // data: ex.response.data,
            error: true,
        };
    }
};
