import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: 'admin-customers',
    component: () => import(/* webpackChunkName: "users" */ '@/layouts/PrincipalLayout.vue'),
    children: [
      {
        path: '',
        name: 'customers-index',
        beforeEnter: [isVerifyTokenGuard],
        meta: {
            breadcrumbs: [
                {
                    transKey: 'breadcrumbs.customers-index',
                    isActive: true
                }
            ]
        },
        component: () => import(/* webpackChunkName: "customers-index" */ '@/modules/admin/customers/views/Index.vue')
    },
    ]
}