import store from '@/store'
export const checkPermission = (permission) => {    
    return store.getters['auth/getLoggedUser']['permissions'].includes(permission)
}
export const removeMenuPermission = (menu) => {
    menu.visible = true
    if (menu.hasChilds) {
        let parentAccess = false
        menu.children.forEach((hijo1) => {
            menu.visible = false
            if (hijo1.hasChilds) {
                hijo1.children.forEach(hijo2 => {
                    if (!checkPermission(hijo2.routeName)) {
                        hijo2.visible = false
                    } else {
                        hijo2.visible = true
                        parentAccess = true
                    }
                })
                hijo1.visible = parentAccess                
            } else {
                if (!checkPermission(hijo1.routeName)) {
                    hijo1.visible = false
                } else {
                    hijo1.visible = true
                    parentAccess = true
                }
            }
            menu.visible = parentAccess            
        })
    } else {
        if (!checkPermission(menu.routeName)) {
            menu.visible = false
        }
    }
}

export const removeRootMenu = (menu) => {
    let access = false
    if (menu.hasChilds) {
        menu.children.forEach(obj => {
            if (obj.visible) {
                access = true
            }
        })
    }
    return access
}