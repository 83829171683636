import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: "psichologists-patients",
    component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
    children: [
        {
            path: "",
            name: "patients-index",
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.clients',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "demo-childres" */ "../views/Index.vue"),
        },
        {
            path: "patient-psicologist/:id/:consultation_id/:room_name?/:dyteToken?",
            name: "patient-details",
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.clients',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.clients-detail',
                        isActive: true
                    }
                ]
            },
            component: () =>
                import(
                    /* webpackChunkName: "patient-details" */ "../views/Patient.vue"
                    ),
        },
        {
            path: "scheduled-sessions",
            name: "scheduled-sessions",
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.scheduled-sessions',
                        isActive: true
                    }
                ]
            },
            component: () => import(
                /* webpackChunkName: "demo-childres" */ "../views/ScheduledSessions.vue"
                ),
        },
        {
            path: "ended-sessions",
            name: "ended-sessions",
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.ended-sessions',
                        isActive: true
                    }
                ]
            },
            component: () => import(
                /* webpackChunkName: "demo-childres" */ "../views/EndedSessions.vue"
                ),
        },
        {
            path: "test-dyte/:token?",
            name: "test-dyte",
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.test-dyte',
                        isActive: true
                    }
                ]
            },
            component: () => import(
                /* webpackChunkName: "demo-childres" */ "../views/TestDyte.vue"
                ),
        },
    ],
};
