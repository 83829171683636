import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: "psychologist-invoicing",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
  children: [
    {
      path: "receivable",
      name: "psychologist-invoicing-receivable",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.psychologist-invoicing-receivable',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "psychologist-invoicing-receivable" */ "@/modules/psycologists/invoicing/views/Receivable.vue"),
    },
    {
      path: "pending-billing",
      name: "psychologist-invoicing-pending-billing",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.psychologist-invoicing-pending-billing',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "psychologist-invoicing-pending-billing" */ "@/modules/psycologists/invoicing/views/PendingBilling.vue"),
    },
    {
      path: "billing",
      name: "psychologist-invoicing-billing",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.psychologist-invoicing-billing',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "psychologist-invoicing-billing" */ "@/modules/psycologists/invoicing/views/Billing.vue"),
    }
  ]
};
