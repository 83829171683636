import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import '@/styles/styles.scss'
import '@/styles/newStyles.scss'
import 'v-calendar/dist/style.css';
// import 'bootstrap'

import i18n from '@/translations/index'

import '@/assets/fontawesome/css/fontawesome.css'
import '@/assets/fontawesome/css/brands.css'
import '@/assets/fontawesome/css/solid.css'
import 'quill/dist/quill.core.css'; // import the Quill core styles
import 'quill/dist/quill.snow.css'; // import the Snow theme styles
import 'quill/dist/quill.bubble.css'; // import the Bubble theme styles
import Select2 from 'vue3-select2-component';

import VCalendar from 'v-calendar';
import { createGtm } from '@gtm-support/vue-gtm';

import { defineCustomElements } from "@dytesdk/ui-kit/loader";

const gm = createGtm({
    id: 'GTM-WFKDGWQF', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']    
    defer: false, 
    compatibility: false, 
    enabled: true, 
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false,
  })  

// NOTE: this defines components so that we can use them in our code.
defineCustomElements();

createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .use(gm)
    .use(VCalendar, {})
    .component('Select2', Select2)
    .mount('#app')
