import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: 'admin-discount-codes',
    component: () => import(/* webpackChunkName: "users" */ '@/layouts/PrincipalLayout.vue'),
    children: [
      {
        path: '',
        name: 'discount-codes-index',
        beforeEnter: [isVerifyTokenGuard],
        meta: {
            breadcrumbs: [
                {
                    transKey: 'breadcrumbs.discount-codes-index',
                    isActive: true
                }
            ]
        },
        component: () => import(/* webpackChunkName: "discount-codes-index" */ '@/modules/admin/discount-codes/views/Index.vue')
    },
    ]
}