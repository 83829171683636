import discountCodesClient from "@/axiosClients/discountCodesClient"
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const createCardMimente = async ({commit}, cardMimenteData) => {
  try {
    const {data: result} = await discountCodesClient.post('/cards', cardMimenteData)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}

export const updateCardMimente = async ({commit}, cardMimenteData) => {
  try {
    const {data: result} = await discountCodesClient.put('/cards', cardMimenteData)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}

export const disableCardMimente = async ({commit}, cardMimenteId) => {
  try {
    const {data: result} = await discountCodesClient.post(`/cards/desactive/${cardMimenteId}`)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}

export const setConsultationAsPaid = async ({commit}, consultationId) => {
  try {
    const {data: result} = await discountCodesClient.post(`/cards/history/set-charged/${consultationId}`)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}