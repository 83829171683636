export const saveAuthStore = (state, { key, data: payload }) => state[key] = payload;

export const setMetricsUUID = (state, uuid) => {
  state.metrics.uuid = uuid;
  setCookie("metricsUUID", uuid, 1);
};

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const clearReasonsPartnerAnwersAndMetric = (state) => {
  sessionStorage.removeItem('reasons');
  sessionStorage.removeItem('partner');
  sessionStorage.removeItem('answers');
  state.reasons = [];
  state.partner = [];
  state.answers = [];
  state.metrics = "";
}

export const clearReasonsPartnerAndAnwers = (state) => {
  sessionStorage.removeItem('reasons');
  sessionStorage.removeItem('partner');
  sessionStorage.removeItem('answers');
  state.reasons = [];
  state.partner = [];
  state.answers = [];
}

export const login = (state, { accessToken, user }) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    state.auth.token = accessToken;
  }
  if (user) {
    sessionStorage.setItem("user", JSON.stringify(user));
    state.auth.user = user;
  }
};

export const logout = (state) => {
  state.auth = {}
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('profile-flow');
}

export const updateInformedConsent = (state, informed_concent) => {
  state.auth.user.accept_informed_consent = informed_concent
  sessionStorage.setItem("user", JSON.stringify(state.auth.user));
}

export const updateToken = (state, {token, expiresIn, date}) => {

  if (token) {
      sessionStorage.setItem('token', token)
      state.auth.token = token      
  }
}

export const setIsFamilyRoute = (state, isFamilyRoute) => {
  state.isFamilyRoute = isFamilyRoute;
}

export const setBrowserTimezone = (state, timezone) => {
  state.auth.timezone = timezone
}