import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'patients',
    component: () => import(/* webpackChunkName: "principal" */ '@/layouts/PrincipalLayout.vue'),
    children: [ 
        {
            path: 'waiting-room',
            name: 'patient-waiting-room',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "patient-waiting-room" */ '@/modules/patients/waiting-room/views/WaitingRoom.vue')
        },
        {
            path: 'card-settings',
            name: 'patient-card-settings',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "card-settings" */ '@/modules/patients/card-settings/views/CardSettings.vue')
        }
    ]
}
