import { errorHttpMessage } from "@/utils/errorDecodeUtil";
import usersService from '@/services/usersService'

export const getMyProfile = async ({ commit }) => {
    try {
        const { data: result } = await usersService.getMyProfile();
        return result.result;
    } catch (ex) {
          const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            // error_keys: error_http_message.error_keys,
            // data: ex.response.data,
            error: true,
        };
    }
};


export const updateMyProfile = async ({ commit }, data) => {
    try {
        const { data: result } = await usersService.updateMyProfile(data);
        return result.result;
    } catch (ex) {
          const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            // error_keys: error_http_message.error_keys,
            // data: ex.response.data,
            error: true,
        };
    }
};
