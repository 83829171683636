import axios from "axios";
import store from "@/store";

const metricsClient = axios.create({
  baseURL: process.env.VUE_APP_METRICS_URL,
  //baseURL: 'https://devdigital.sherpa.la/authentication/api/v1'
  //baseURL: 'http://localhost/authentication/api/v1'
  //baseURL: 'http://main-webserver-web/authentication/api/v1'
});

metricsClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters["auth/getToken"]}`;
  config.headers.lang = store.getters["getLang"];
  return config;
});

export default metricsClient;
