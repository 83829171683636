import axios from "axios";
import store from "@/store";

const discountCodesClient = axios.create({
  baseURL: process.env.VUE_APP_DISCOUNT_CODES_URL
});

discountCodesClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters["auth/getToken"]}`;
  config.headers.lang = store.getters["getLang"];
  return config;
});

export default discountCodesClient;