const getValue = (key) =>{
  try {
    if (sessionStorage.getItem(key)) {
      return JSON.parse(sessionStorage?.getItem(key));
    } else {
      return [];
    }    
  } catch (error) {
    return []
  }
}
const getObjectValue = (key) => {
  try {
    if (sessionStorage.getItem(key)) {
      const arr = JSON.parse(sessionStorage.getItem(key));
      return arr
    } else {
      return [];
    }
  } catch (error) {
    return []
  }
}
export default () => ({
  reasons: getValue('reasons'),
  partner: getValue('partner'),
  answers: getObjectValue('answers'),
  metrics: {
    uuid: getCookie('metricsUUID')
  },
  auth: {
    user: JSON.parse(sessionStorage.getItem('user')) || null,
    token: sessionStorage.getItem('token') || null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null //'Etc/GMT+6'
  },
  isFamilyRoute: false
});

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}