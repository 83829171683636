import patientClient from "@/axiosClients/patientClient"
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const addCourtesySessions = async ({commit}, courtesyData) => {
  try {
    const {data: result} = await patientClient.post('/update-courtesy-sessions', courtesyData)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}