import videocallService from "@/services/videocallService";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const issFirstSession = async ({commit}, formData) => {
    try {

        const {data: response} = await videocallService.isFirstSession(formData)

        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}
