import configurationService from "@/services/configurationService";
import psychologistConsultationService from "@/services/psychologistConsultationService";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const setDefaultPaymentCard = async ({commit}, data) => {
  try {
    const {data: response} = await psychologistConsultationService.setDefaultPaymentCard(data);
    return response.result;
  } catch (ex) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message?.message,
        error_keys: error_http_message?.error_keys,
        data: ex?.response?.data,
        error: true,
      };
  }
}