import discountCodesClient from "@/axiosClients/discountCodesClient"
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const createDiscountCode = async ({commit}, discountCodeData) => {
  try {
    const {data: result} = await discountCodesClient.post('/', discountCodeData)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}

export const disableDiscountCode = async ({commit}, discountCodeId) => {
  try {
    const {data: result} = await discountCodesClient.post(`/desactive/${discountCodeId}`)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}