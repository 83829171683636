import moment from 'moment'

export const addIntervalToDay = ( state, data ) => {    
    let day = state.rules.days.find(el => el.key == data.day)
    day.status = true
    const interval = {
        hour_start: moment(data.start, 'HH:mm:ss').format('hh:mm a'),
        hour_end: moment(data.end, 'HH:mm:ss').format('hh:mm a')
    }
    day.intervals.push(interval)
}

export const deleteIntervalToDay = ( state, data ) => {    
    let day = state.rules.days.find(el => el.key == data.key)
    day.intervals.splice(data.index, 1)
    if (day.intervals.length == 0) {
        day.status = false
    }
}

export const setAvailabilityData = (state, data) => {
    state.rules.interval = data.interval;
    state.rules.max_duration = data.max_duration;
    state.rules.max_sesion = data.max_sesion;
    state.rules.days.forEach(el => {
        el.status = !!data[el.key + '_status']
        if (data[el.key]) {
            el.intervals = data[el.key].map(interval => ({
                hour_start: moment(interval.hour_start, 'HH:mm:ss').format('hh:mm a'),
                hour_end: moment(interval.hour_end, 'HH:mm:ss').format('hh:mm a')
            }))
        }
    })
}

export const addBufferRule = (state, data) => {
    state.rules.interval = data
}

export const addMaxHoursRule = (state, data) => {
    state.rules.max_hours = data
}

export const addFromRule = (state, data) => {
    state.rules.from = data
}

export const setShowSkipButton = (state, value) => {
    state.showSkipButton = value
}