export default () => ({
    action: null,
    rules: {
      days: [{
          key: 'monday',
          name: 'Lunes',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "06:00 pm"
            }
          ],
          status: true
        },
        {
          key: 'tuesday',
          name: 'Martes',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "06:00 pm"
            }
          ],
          status: true
        },
        {
          key: 'wednesday',
          name: 'Miércoles',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "06:00 pm"
            }
          ],
          status: true
        },
        {
          key: 'thursday',
          name: 'Jueves',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "06:00 pm"
            }
          ],
          status: true
        },
        {
          key: 'friday',
          name: 'Viernes',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "06:00 pm"
            }
          ],
          status: true
        },
        {
          key: 'saturday',
          name: 'Sábado',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "02:00 pm"
            }
          ],
          status: false
        },
        {
          key: 'sunday',
          name: 'Domingo',
          intervals: [
            {
              hour_start : "09:00 am",
              hour_end : "02:00 pm"
            }
          ],
          status: false
        }
      ],
      interval : 0,
      max_duration : 8,
      max_sesion: 0,
      from : {
        value: 2,
        display: 'Mañana'
      }
    },
    showSkipButton: true,
  })