import axios from 'axios'
import store from '@/store'

const authClient = axios.create({
    baseURL: process.env.VUE_APP_AUTHENTICATION_URL
})

authClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${store.getters['auth/getToken']}`
    config.headers.lang = store.getters['getLang']
    return config;
})

export default authClient