import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/layouts/PrincipalLayout.vue'),
    children: [
        {
            path: '',
            name: 'users-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.users-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "users-index" */ '@/modules/users/views/Index.vue')
        },
        {
            path: 'create',
            name: 'users-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [   
                    {
                        route: {
                            name: 'users-index',
                        },
                        transKey: 'breadcrumbs.users-index',
                        isActive: false,
                    },                
                    {                           
                        transKey: 'breadcrumbs.users-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "users-create" */ '@/modules/users/views/Create.vue')
        },
        {
            path: 'detail/:user_id',
            name: 'users-detail',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [     
                    {
                        route: {
                            name: 'users-index',
                        },
                        transKey: 'breadcrumbs.users-index',
                        isActive: false,
                    },           
                    {                           
                        transKey: 'breadcrumbs.users-detail',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "users-detail" */ '@/modules/users/views/Detail.vue')
        }
    ]
}