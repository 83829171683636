import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: "admin-sessions",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
  children: [
    {
      path: "cupon-history",
      name: "admin-cupon-history",
      // beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.admin-cupon-history',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-cupon-history" */ "@/modules/admin/sessions/views/CuponCodeHistory.vue"),
    },
    {
      path: "cupon-history-detail/:cuponCode",
      name: "admin-cupon-history-detail",
      // beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            route: {
              name: 'admin-cupon-history',
              params: {}
            },
            transKey: 'breadcrumbs.admin-cupon-history',
            isActive: false
          },
          {
            transKey: 'breadcrumbs.admin-cupon-history-detail',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-cupon-history-detailt" */ "@/modules/admin/sessions/views/CuponCodeHistoryDetail.vue"),
    }
  ]  
};
