import usersClient from "@/axiosClients/usersClient";

const getInterviewers = () => { return usersClient.get(`interviewers`) }
const getUserById = (id) => usersClient.get(`/show/patient/${id}`);
const setUserById = ({id, data}) => usersClient.post(`/patient/${id}`, data);
const getMyProfile = () => usersClient.get(`/my/profile`);
const updateMyProfile = (data) => usersClient.post(`/my/profile`, data);
const saveConsultReasonsToUser = (data) => usersClient.post('/consult-reasons', data);

export default {
  getInterviewers,
  getUserById,
  setUserById,
  getMyProfile,
  updateMyProfile,
  saveConsultReasonsToUser
}