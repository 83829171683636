import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import hasAccessGuard from "@/guards/hasTokenGuard";

export default {
    name: "privacy",
    component: () => import(/* webpackChunkName: "users" */ '@/layouts/PrincipalLayout.vue'),
    children: [
        {
            path: '',
            name: 'users-privacy-index',
            beforeEnter: [hasAccessGuard],
            component: () => import( /* webpackChunkName: "privacy-index" */ '@/modules/privacy/views/Index.vue'),
        },
        {
            path: 'terms-and-conditions',
            name: 'user-terms-conditions',
            beforeEnter: [hasAccessGuard],
            component: () => import( /* webpackChunkName: "privacy-index" */ '@/modules/privacy/views/TermsAndConditions.vue'),
        }
    ]
}
