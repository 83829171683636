import catalogsClient from '@/axiosClients/catalogsClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationService from "@/services/configurationService"
import catalogsService from '@/services/catalogsService'

export const getLanguages = async ({ commit }) => {
    try{        
        const response = await configurationService.getLanguages()                
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const savePersonalConfiguration = async ({ commit }, data) => {
    try{        
        const response = await configurationService.savePersonalConfiguration(data)                
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getFromDates = async ({ commit }) => {
    try{        
        const response = await configurationService.getFromDates()                
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const saveAvailabilityConfiguration = async ({ commit }, data) => {
    try{        
        const response = await configurationService.saveAvailabilityConfiguration(data)                
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const saveIntegrationConfiguration = async ({ commit }, data) => {
    try{        
        const response = await configurationService.saveIntegrationConfiguration(data)                
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getIntegrationGoogleUrl = async ({commit}) => {    
    try {
        const {data: result} = await configurationService.getIntegrationGoogleUrl()
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const googleIntegration = async ({commit}, authCode) => {
    try {
        const params = {
            auth_code: authCode
        }
        const {data: result} = await configurationService.googleIntegration(params)
        // const {data, message, error} = result.result
        // commit('login', {user: {providerId: data.provider_id, ...data.user}, accessToken: data.token, refreshToken: null, expiresIn: null, date:null, host:null, isLandlord:null})
        // return data.result
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const getTimeZones = async ({ commit }) => {
    try{        
        const response = await catalogsClient.get(`/timezones`)                
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const savePsychologistPaymentInformation = async ({commit}, data) => {
    try{        
        const response = await configurationService.savePsychologistPaymentInformation(data);
        return response.data.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getEmailIntegrationConfiguration = async ({ commit }, id) => {
    try {
        const response = await configurationService.getEmailIntegrationConfiguration(id);
        return response.data.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getAvailabilityConfiguration = async ({ commit }, id) => {
    try {
        const response = await configurationService.getAvailabilityConfiguration(id);
        return response.data.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getPaymentInformationConfiguration = async ({ commit }, id) => {
    try {
        const response = await configurationService.getPaymentInformationConfiguration(id);
        return response.data.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getCountries = async ({ commit }) => {
    try {
        const response = await catalogsService.getCountries();
        return response.data.result.data
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getTaxRegimes = async ({ commit }) => {
    try {
        const response = await catalogsService.getTaxRegimes();
        return response.data.result.data
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const setCalendarStatus = async ({commit}, status) => {
    try {
        const response = await configurationService.setCalendarStatus(status);
        return response.data.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
