import psychologistConsultationService from '@/services/psychologistConsultationService';
import psychologistService from '@/services/psychologistService';
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const getResume = async ({commit}, data) => {
    try {
        const {data: response} = await psychologistConsultationService.getResume(data);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const setPaymentProcess = async ({commit}, data) => {
    try {
        const {data: response} = await psychologistConsultationService.setPaymentProcess(data);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const getAvailableDatesForPsychologits = async ({commit}, options) => {
  let promises = [];
  options.psychologists.forEach(el => promises.push(psychologistService.getAvailableDates({...options.data, id: el})))
  try {
    const response = await Promise.all(promises);
    return response.map(el => el.data.result.data);
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}

export const updatePsychologistConsultation = async ({commit}, data) => {
  try {
    const {data: response} = await psychologistConsultationService.updatePsychologistConsultation(data);
    return response.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}
export const getCards = async ({commit}, data) => {
    try {
        const {data: response} = await psychologistConsultationService.getCards();
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const attachCardToCustomer = async ({commit}, formData) => {
    try {
        const {data: response} = await psychologistConsultationService.setCardToCustomer(formData);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}


export const deleteCard = async ({commit}, cardId) => {
    try {
        const {data: response} = await psychologistConsultationService.deleteCard(cardId);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const getPsychologistByLinkname = async ({commit}, linkname) => {
    try {
        const {data: response} = await psychologistService.getPsychologistById(linkname);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const addMinutesToPenddingPayment = async ({commit}, data) => {
    try {
        const {data: response} = await psychologistConsultationService.addMinutesToPenddingPayment(data);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const saveRecurrency = async ({commit}, data) => {
    try {
        const {data: response} = await psychologistConsultationService.checkoutRecurrency(data);
        commit('psycologistsOptions/clearPsychologist', null , {root: true});
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}

export const applyDiscountCode = async ({commit}, data) => {
    try {
        const {data: response} = await psychologistConsultationService.applyDiscountCode(data);
        return response.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message?.message,
            error_keys: error_http_message?.error_keys,
            data: ex?.response?.data,
            error: true,
        };
    }
}
