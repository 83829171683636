import configurationClient from "@/axiosClients/configurationClient";

const savePsychologistPaymentInformation = (data) => {
    return configurationClient.post(`/psychology/payment-information`, data);
}

const getLanguages = () => {
    return configurationClient.get(`/languages`)                
}

const savePersonalConfiguration = (data) => {
    return configurationClient.post(`/configuration-general`, data);
}

const getFromDates = () => {
    return configurationClient.get(`/from`);
}

const saveAvailabilityConfiguration = (data) => {
    return configurationClient.post(`/psychology`, data);
}

const saveIntegrationConfiguration = (data) => {
    return configurationClient.post(`/configuration-calendar`, data);
}

const getIntegrationGoogleUrl = () => {
    return configurationClient.get('/google/calendar');
}

const googleIntegration = (params) => {
    return configurationClient.post('/google/calendar', params)
}

const getEmailIntegrationConfiguration = (id) => {
    return configurationClient.get(`verify-configuration-calendar/${id}`);
}

const getAvailabilityConfiguration = (id) => {
    return configurationClient.get(`psychology/schedules/${id}`);
}

const getPaymentInformationConfiguration = (id) => {
    return configurationClient.get(`psychology/payment-information/${id}`);
}

const setCalendarStatus = (status) => {
    return configurationClient.post('/google/calendar/status', status);
}

const saveAgeConfiguration = (data) => {
    return configurationClient.post(`psychology/ages-attends/${data.userId}`, data.ranges);
}

const getAgeConfiguration = (userId) => {
    return configurationClient.get(`psychology/ages-attends/${userId}`);
}

const saveConsultResonConfiguration = (data) => {
    return configurationClient.post(`psychology/reason-consultations/${data.userId}`, data.reasons);
}

const saveConsultResonTop5Configuration = (data) => {
    return configurationClient.post(`psychology/reason-consultations/top/${data.userId}`, data.reasons);
}

const getConsultReasonsExperience = (userId) => {
    return configurationClient.get(`psychology/reason-consultations/${userId}`);
}

export default {
    savePsychologistPaymentInformation,
    getLanguages,
    savePersonalConfiguration,
    getFromDates,
    saveAvailabilityConfiguration,
    saveIntegrationConfiguration,
    getIntegrationGoogleUrl,
    googleIntegration,
    getEmailIntegrationConfiguration,
    getAvailabilityConfiguration,
    getPaymentInformationConfiguration,
    setCalendarStatus,  
    saveAgeConfiguration,
    getAgeConfiguration,
    saveConsultResonConfiguration,
    saveConsultResonTop5Configuration,
    getConsultReasonsExperience
}