import hasAccessGuard from "@/guards/hasTokenGuard";

export default {
  name: "terapist-options",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/BlankLayout.vue"),
  children: [
    {
      path: "", 
      name: "psicologists-options",
      beforeEnter: [hasAccessGuard],
      component: () => import(
          /* webpackChunkName: "landing-page" */ "@/modules/psycologists-options/views/Index.vue"
        ),
    },
    {
      path: "details/:id",
      name: "psychologist-details",
      beforeEnter: [hasAccessGuard],
      component: () => import(
          /* webpackChunkName: "psychologist-details" */ "@/modules/psycologists-options/views/psychologist.vue"
        ),
    },
    
  ],
};