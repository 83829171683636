
export const getCheckoutInfo = ( state ) => {
 return {...state.checkoutInfo};
}

export const getAppointmentToUpdate = (state) => {
    return {...state.appointmentToUpdate}
}


