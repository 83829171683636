import hasAccessGuard from "@/guards/hasTokenGuard";
import landingGuard from '@/guards/landingGuard';

export default {
    name: 'home',
    component: () => import(/* webpackChunkName: "auth-layout" */ '@/layouts/BlankLayout.vue'),
    children: [
        {
            path: '',
            name: 'landing-page',
            beforeEnter: [landingGuard],
            component: () => import(/* webpackChunkName: "landing-page" */ '@/modules/principal/views/LandingPageV4.vue'),
        },
        {
            path: 'login',
            name: 'login-page',
            // beforeEnter: [hasAccessGuard],
            // component: () => import(/* webpackChunkName: "landing-page" */ '@/modules/principal/views/login.vue'),
            beforeEnter: [landingGuard],
            component: () => import(/* webpackChunkName: "login-page" */ '@/modules/principal/views/LandingPageV3.vue'),
        },
        {
            path: 'questions',
            name: 'questions-page',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "questions-page" */ '@/modules/principal/views/Questions.vue'),
        },
        {
            path: 'reasons',
            name: 'reasons-page',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "reasons-page" */ '@/modules/principal/views/ReasonsForConsultation.vue'),
        },
        {
            path: 'partner',
            name: 'partner-page',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "partner-page" */ '@/modules/principal/views/Partner.vue'),
        },
        {
            path: 'im-psychologist',
            name: 'im-psychologist-page',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "partner-page" */ '@/modules/principal/views/ImPsychologist.vue'),
        },
        {
            path: 'psicologo/:psychologiLink',
            name: 'schedule-appointment-external',
            component: () => import(/* webpackChunkName: "schedule-appointment" */ '@/modules/appointments/views/AppointmentWrapper.vue')
        },
        {
            path: 'privacy-notice',
            name: 'privacy-notice',
            component: () => import(/* webpackChunkName: "privacy-notice" */ '@/modules/principal/views/PrivacyNotice.vue')
        },
        {
            path: 'terms',
            name: 'terms-and-conditions',
            component: () => import(/* webpackChunkName: "privacy-notice" */ '@/modules/privacy/views/TermsAndConditions.vue')
        },
        {
            path: 'familia',
            name: 'landing-page-family',
            beforeEnter: [landingGuard],
            component: () => import(/* webpackChunkName: "landing-page" */ '@/modules/principal/views/LandingPageV3.vue'),
        },
        {
            path: ':tenantName',
            name: 'landing-page-company-register',
            beforeEnter: [landingGuard],
            component: () => import(/* webpackChunkName: "landing-page" */ '@/modules/principal/views/LandingPageV4.vue'),
        },
        {
            path: 'personal',
            name: 'personal-lading-page',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "reasons-page" */ '@/modules/principal/views/LandingPageV3.vue'),
        },
        {
            path: 'landing-v4',
            name: 'landing-v4',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "reasons-page" */ '@/modules/principal/views/LandingPageV4.vue'),
        },
        {
            path: 'life-line',
            name: 'landing-life-line',
            beforeEnter: [landingGuard],
            component: () => import(/* webpackChunkName: "landing-life-line" */ '@/modules/waiting-room/views/LifeLine.vue')
        }
    ]
}
