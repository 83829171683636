export const findDayByKey = (state, day) => {
    return {
        ...state.rules.days.find(el => el.key == day)
    }
}

export const getRules = (state) => {
    return {
        ...state.rules
    }
}

export const getActiveDays = (state) => {
    return [...state.rules.days.map(el => ({
        display: el.name,
        value: el.key
    }))]
}

export const getShowSkipButton = (state) => {
    return state.showSkipButton
}