import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'psycologists-configurations',
    component: () => import(/* webpackChunkName: "psycologists" */ '@/layouts/PrincipalLayout.vue'),
    // component: () => import(/* webpackChunkName: "psycologists" */ '@/modules/psycologists/configuration/layouts/ConfigurationLayout.vue'),
    children: [
        {
            path: 'calendar-integration', 
            name: 'psycologist-configuration-calendar-integration',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "psycologists-calendar-integration" */ '@/modules/psycologists/configuration/views/CalendarIntegration.vue')
        },
        {
            path: 'availability', 
            name: 'psycologist-configuration-availablity',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "psycologists-availability" */ '@/modules/psycologists/configuration/views/AvailabilityConfiguration.vue')
        },
        {
            path: 'payment', 
            name: 'psycologist-configuration-payment',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "psycologists-availability" */ '@/modules/psycologists/configuration/views/PaymentInformation.vue')
        },
        {
            path: 'age-range', 
            name: 'psycologist-configuration-age-range',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "psycologists-availability" */ '@/modules/psycologists/configuration/views/AgeConfiguration.vue')
        },
        {
            path: 'consult-reasons', 
            name: 'psycologist-configuration-consult-reasons',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "psycologists-availability" */ '@/modules/psycologists/configuration/views/ConsultReasonsConfiguration.vue')
        }
    ]    
}