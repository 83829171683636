import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import hasAccessGuard from "@/guards/hasTokenGuard";

export default {
  name: "profile-psychologist",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
  children: [
    {
      path: "",
      name: "profile-psychologist-index",
      beforeEnter: [hasAccessGuard],
      component: () => import(/* webpackChunkName: "demo-childres" */ "../views/Index.vue"),
    },
    
  ],
}; 
