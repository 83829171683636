import axios from "axios";
import store from "@/store";

const configurationClient = axios.create({
  baseURL: process.env.VUE_APP_PATIENT_URL,
  //baseURL: 'https://devdigital.sherpa.la/authentication/api/v1'
  //baseURL: 'http://localhost/authentication/api/v1'
  //baseURL: 'http://main-webserver-web/authentication/api/v1'
});

configurationClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters["auth/getToken"]}`;
  // config.headers.Authorization = `4|X21hFmVKQVu4NthiFUHvERI1tABwz9z1kIItgem17OHY3GK2izEsIL6ViluVgEjciLXkYyZOQb0Kcuz1dDPlqtHCWFJbAG8lukYLyqPaV1c5d9p7Pq4RXp9kC2x7DWS5t264fazrvYr41HoFfqypPTzLo2a7CUhachjYmX5iE7KxnrQ5yDAMyvM7vqkQUlvmmnsmtCTMMH7CErGMTEmhCzZIt8TEuHsawsA5H8WiBFwMElY4c4cexlKfj7`
  config.headers.lang = store.getters["getLang"];
  return config;
});

export default configurationClient;