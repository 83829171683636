import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: 'admin-cards-mimente',
    component: () => import(/* webpackChunkName: "cards-mimente" */ '@/layouts/PrincipalLayout.vue'),
    children: [
        {
            path: '',
            name: 'cards-mimente-index',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.cards-mimente-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "cards-mimente-index" */ '@/modules/admin/cards-mimente/views/Index.vue')
        },
        {
            path: 'history',
            name: 'cards-mimente-history',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.cards-mimente-history',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "cards-mimente-history" */ '@/modules/admin/cards-mimente/views/History.vue')
        },
        {
            path: 'history-detail/:businessCardId',
            name: 'cards-mimente-history-detail',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                          name: 'cards-mimente-history',
                          params: {}
                        },
                        transKey: 'breadcrumbs.cards-mimente-history',
                        isActive: false
                      },
                      {
                        transKey: 'breadcrumbs.cards-mimente-history-detail',
                        isActive: true
                      }
                ]
            },
            component: () => import(/* webpackChunkName: "cards-mimente-history-detail" */ '@/modules/admin/cards-mimente/views/HistoryDetail.vue')
        },
        {
            path: 'assign/:idCard',
            name: 'cards-mimente-assign',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                {
                    route: {
                    name: 'cards-mimente-index',
                    params: {}
                    },
                    transKey: 'breadcrumbs.cards-mimente-index',
                    isActive: false
                },
                {
                    transKey: 'breadcrumbs.cards-mimente-assign',
                    isActive: true
                }
                ]
            },
            component: () => import(/* webpackChunkName: "cards-mimente-index" */ '@/modules/admin/cards-mimente/views/Assing.vue')
        },
        {
            path: 'view/:idCard',
            name: 'cards-mimente-view',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                    route: {
                        name: 'cards-mimente-index',
                        params: {}
                    },
                    transKey: 'breadcrumbs.cards-mimente-index',
                    isActive: false
                    },
                    {
                        transKey: 'breadcrumbs.cards-mimente-detail',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "cards-mimente-index" */ '@/modules/admin/cards-mimente/views/Detail.vue')
        },
        {
            path: 'edit/:idCard',
            name: 'cards-mimente-edit',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                    route: {
                        name: 'cards-mimente-index',
                        params: {}
                    },
                    transKey: 'breadcrumbs.cards-mimente-index',
                    isActive: false
                    },
                    {
                        transKey: 'breadcrumbs.cards-mimente-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "cards-mimente-index" */ '@/modules/admin/cards-mimente/views/Edit.vue')
        },
    ]
}