import hasAccessGuard from "@/guards/hasTokenGuard";

export default {
    name: 'video',
    component: () => import('@/layouts/VideocallLayout.vue'),
    children: [
        {
            path: ':room_name',
            name: 'index-video',
            beforeEnter: [hasAccessGuard],
            component: () => import('@/modules/videocall/views/Index.vue')
        },
        {
            path: 'dyte/:room_name/:dyte_token',
            name: 'dyte-video',
            // beforeEnter: [hasAccessGuard],
            component: () => import('@/modules/videocall/views/DyteIndex.vue')
        }
    ]
}
