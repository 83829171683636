import store from '@/store'
import sweetalertUtil from '@/utils/sweetalertUtil'
import i18n from '@/translations'
import {checkPermission} from '@/utils/permissionsUtil'

const verifyTokenGuard = async (to, from, next) => {

    const token = store.getters["auth/getToken"]
    const isExpired = store.getters['auth/isExpired']
    const loggedUser = store.getters['auth/getLoggedUser']

    if (isExpired) {

        const {message, error} = await store.dispatch('auth/refreshToken')
        if (error) {
            store.commit('auth/logout')
            next({name: 'login-page'})
        }

        if (checkPermission(to.name)) {
            next()
        } else {

            sweetalertUtil.fire({
                icon: 'error',
                title: 'Error',
                html: i18n.global.messages.value[i18n.global.locale.value]['errors']['has-permission'],
            })
            next({name: from.name})
        }

        next({name: 'login-page'})

    } else if (!token || isExpired) {
    // if (!token) {
        next({name: 'login-page'})
    } else {
        if (checkPermission(to.name)) {
            next()
        } else {
            sweetalertUtil.fire({
                icon: 'error',
                title: i18n.global.messages.value[i18n.global.locale.value]['alert-title-message'],
                html: i18n.global.messages.value[i18n.global.locale.value]['errors']['has-permission'],
            })
            next({name: from.name})
        }
    }

}

export default verifyTokenGuard
