export default {
  name: "profile-patient",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
  children: [
    {
      path: "",
      name: "profile-patient-index",
      component: () => import(/* webpackChunkName: "profile-patient-index" */ "../views/Index.vue"),
    },
    
  ],
};
