import store from '@/store'
import router from "@/router";

const hasAccessGuard = async (to, from, next) => {

    const token = store.getters["auth/getToken"]
    const refreshToken = store.getters["auth/getRefreshToken"]

    if (token && refreshToken) {
        next({name: 'dashboard-index'})
    }

    next()

}

export default hasAccessGuard