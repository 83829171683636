import en from '@/translations/en.json'
import es from '@/translations/es.json'
import { createI18n } from 'vue-i18n'
import store from '@/store'

const i18n = createI18n({
    legacy: false,
    messages: {
        en, es
    },
    locale: store.getters['getLang'], // set locale
    fallbackLocale: 'es', // set fallback locale
})

export default i18n