import catalogsClient from '@/axiosClients/catalogsClient'

// const catalogsService = () => {
//   const getRoles = () => { return catalogsClient.get(`/roles`) }
//   return {
//     getRoles
//   }
// }

const getRoles = (data = {}) => { return catalogsClient.get(`/roles?${data.params}`) }
const getGenders = () => { return catalogsClient.get(`/genders`) }
const getNationalities = () => { return catalogsClient.get(`/nationalities`) }
const getFederalEntities = () => {return catalogsClient.get(`/federal-entities`)}
const getReligions = () => { return catalogsClient.get(`/religions`) }
const getWorkWiths = () => { return catalogsClient.get(`work-withs`) }
const getPsicologicalApproaches = () => { return catalogsClient.get(`psychological-approaches`) }
const getDegrees = () => { return catalogsClient.get(`degrees`) }
const getCategoryPrices = () => { return catalogsClient.get(`category-price`) }
const getSocialNetworks = () => { return catalogsClient.get(`social-network`) }
const getPsicologistStatus = () => { return catalogsClient.get(`psychologist-status`) }
const getCountries = () => { return catalogsClient.get(`countries`) }
const getTaxRegimes = () => { return catalogsClient.get(`tax-regimes`) }
const getReasonsConsultation = () => { return catalogsClient.get(`/reason-consultation`) }
const getRecurrings = () => { return catalogsClient.get(`/recurrings`) }
const getTenantsList = () => {return catalogsClient.get(`/tenants`) }
const getSecurityQuestions = () => {return catalogsClient.get(`/security-questions`)}
const getConsultationCancellationTypes = () => {return catalogsClient.get(`/consultation-cancellation-types`)}
const getRelationships = () => {return catalogsClient.get(`/relationships`)}
const getPsychologies = () => {return catalogsClient.get(`/psychologies`)}
const getScales = () => {return catalogsClient.get(`/scales`)}
const getSubgroupsByTenant = (tenantId) => {return catalogsClient.get(`/subgroups/${tenantId}`)}
const getConsultationTypes = () => {return catalogsClient.get(`/psychology-consultation-types`)}
const validateTenant = (data) =>{return catalogsClient.post(`/is-tenant-valid`, data)}
const getSliderByType = (type) =>{return catalogsClient.get(`/slider/${type}`)}

export default {
  getRoles,
  getGenders,
  getNationalities,
  getFederalEntities,
  getReligions,
  getWorkWiths,
  getPsicologicalApproaches,
  getDegrees,
  getCategoryPrices,
  getSocialNetworks,
  getPsicologistStatus,
  getCountries,
  getTaxRegimes,
  getReasonsConsultation,
  getRecurrings,
  getTenantsList,
  getSecurityQuestions,
  getConsultationCancellationTypes,
  getRelationships,
  getPsychologies,
  getScales,
  getSubgroupsByTenant,
  validateTenant,
  getConsultationTypes,
  getSliderByType
}