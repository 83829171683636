import router from "@/router";
import {useStore} from 'vuex';


//Decode error from API when return a bad request
export function errorHttpMessage(http_request) {

    const response = http_request.response;

    if (response != undefined) {

        const error_status = response.status;
        const store = useStore()

        if (error_status == 422) {

            //Laravel error request
            let res = JSON.parse(response.request.response);
            let errors = Object.keys(res.errors);
            let empty = [];
            let error_keys = [];
            let subtexts = '';

            errors.forEach((element) => {
                let filteredValues = res.errors[element];

                error_keys.push(element);

                filteredValues.forEach((sub_element) => {
                    empty.push(`<li class="li_details_error">${sub_element}</li>`)
                });

                return empty;
            });

            subtexts = empty.join("");

            return {"message": subtexts, "error_keys": error_keys};

        } else if (error_status == 401) {

            sessionStorage.clear()
            router.push({name: 'login-page'})

            return {"message": messsage, "error_keys": null};
        }

    } else {

        console.log(http_request)
        return {"message": "No hemos podido procesar tu solicitud, inténtalo nuevamente.", "error_keys": null};

    }

}


export function setErrorKey(key_with_error) {

    let error_keys = {};

    if (key_with_error != undefined) {
        key_with_error.forEach((element) => {
            error_keys[element] = true;
        });
    }

    //console.log(error_keys);

    return error_keys;
}
