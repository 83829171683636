export const setPsychologits = (state, data) => {
    if (!state.psychologitsArray) {
        state.psychologitsArray = [];        
    }
    data.matching.forEach(el => state.psychologitsArray.push(el));
    if (data.others) {
        data.others.forEach(el => state.psychologitsArray.push(el));
    }
    state.psychologits = data;
    sessionStorage.setItem('matching-psychologits', JSON.stringify(state.psychologitsArray))
}

export const setOnePsychologist = (state, psychologist) => {
    if (!state.psychologitsArray) {
        state.psychologitsArray = [];        
    }
    state.psychologitsArray.push(psychologist);
    sessionStorage.setItem('matching-psychologits', JSON.stringify(state.psychologitsArray))
}

export const clearPsychologist = (state) => {
    state.psychologitsArray = null;
    state.psychologits = null;
    sessionStorage.removeItem('matching-psychologits');
}