export default {
    name: 'scales',
    component: () => import(/* webpackChunkName: "scales-principal" */ '@/layouts/PrincipalLayout.vue'),
    children: [
        {
            path: ':id',
            name: 'scales-index',
            component: () => import(/* webpackChunkName: "scales-index" */ '@/modules/questionnario-scales/views/Index.vue')
        },
        {
            path: ':id/:questionnaireId',
            name: 'scales-show',
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'assignments-concentrated-detail',
                            params: {}
                        },
                        transKey: 'breadcrumbs.clients-detail',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.questionnaire-scales',
                        isActive: true,
                    }
                    
                ]
            },
            component: () => import(/* webpackChunkName: "scales-index" */ '@/modules/questionnario-scales/views/Index.vue')
        },
        {
            path: 'initial/:patientId',
            name: 'questionnaire-initial-show',
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'assignments-concentrated-detail',
                            params: {}
                        },
                        transKey: 'breadcrumbs.clients-detail',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.questionnaire-initial',
                        isActive: true,
                    }
                    
                ]
            },
            component: () => import(/* webpackChunkName: "scales-index" */ '@/modules/questionnario-scales/views/Initial.vue')
        },
        {
            path: '/admin-scales',
            name: 'admin-scales-main',
            meta: {
                breadcrumbs: [                    
                    {                           
                        transKey: 'breadcrumbs.questionnaire-scales',
                        isActive: true,
                    }
                    
                ]
            },
            component: () => import(/* webpackChunkName: "scales-index" */ '@/modules/questionnario-scales/views/Admin.vue')
        },
    ]    
}