import catalogsClient from "@/axiosClients/catalogsClient.js";
import questionnairesClient from "@/axiosClients/questionnairesClient.js";
import authClient from "@/axiosClients/authClient.js";
import metricsClient from "@/axiosClients/metricsClient";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";
import psychologistService from '@/services/psychologistService'
import usersService from "@/services/usersService";


export const getCatalogs = async ({ commit }, params = {}) => {
  try {
    const { data: result } = await catalogsClient.get(`/reason-consultation`);
    return result.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const getQuestionnaires = async ({ commit }) => {
  try {
    const { data: result } = await questionnairesClient.get(`/initial`);
    const { message, error } = result;
    return { data: result.result.data.questions, message, error };
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};
 
export const registerUser = async ({ commit }, user) => {
  try {
    const { data: result } = await authClient.post(`/register`, user);
    const { message, error, data } = result.result;    
    if (!error) {
      const { access_token, user } = data;
      commit("login", { accessToken: access_token, user });
    }
    return { message, error };
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const registerB2bUser = async ({ commit }, b2bUser) => {
  try {
    const endpoint = b2bUser.isFamily ? '/b2b/register/family' : '/b2b/register';
    const { data: result } = await authClient.post(endpoint, b2bUser);
    const { message, error, data } = result.result;

    if (!error) {
      const { access_token, user } = data;
      commit("login", { accessToken: access_token, user });
    }

    return { data, message, error };

  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const loginUser = async ({ commit }, { password, email_or_nomina }) => {
  try {
    const { data: result } = await authClient.post("/login", {
      password,
      email_or_nomina,
    });
    const { error, message } = result.result;
    const data = result.result.data.user || null;
    if (!error) {
      const { access_token, expires_in, user } = result.result.data;
      commit("login", { accessToken: access_token, user, expires_in });
    }
    return { data, error, message };
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      // data: ex.response.data,
      error: true,
    };
  }
};

export const sendCodeUser = async ({ commit }, recoverPassData) => {
  try {
    const { data } = await authClient.post(`/reset-password/code`, recoverPassData);
    return data.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const resetPass = async ({ commit }, newPassData) => {
  try {
    const { data } = await authClient.post(`/reset-password`, newPassData);
    return data.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const saveInterested = async ({ commit },params) => {
  try {
    const { data } = await psychologistService.saveInterested(params);
    return data.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
}; 

export const refreshToken = async ({commit}) => {

  try {      

    const {data: result} = await authClient.post('/refresh')
    const {data, message, error} = result.result
    const {access_token} = data

    if (!error) {
        commit('updateToken', {token: access_token})
    }

    return {message, error}

  } catch (ex) {
      const error_http_message = errorHttpMessage(ex);
      return {message: error_http_message.message, data: ex, error: true}
  }
}

export const saveConsultReasonsToUser = async ({commit}, data) => {

  try {
    const {data: result} = await authClient.post('/user/consult-reasons', data)
    const {message, error} = result.result

    if (!error) {
        commit('clearReasonsPartnerAndAnwers')
    }

    return {message, error}

  } catch (ex) {
      const error_http_message = errorHttpMessage(ex);
      return {message: error_http_message.message, data: ex, error: true}
  }
}

export const sendSecurityQuestions = async ({commit}, securityQuestionsData) => {
  try {
    const {data: result} = await authClient.post('/validate-security-question', securityQuestionsData)
    const {data, message, error} = result.result
    return {data, message, error}

  } catch (ex) {
      const error_http_message = errorHttpMessage(ex);
      return {message: error_http_message.message, data: ex, error: true}
  }
}

export const resetB2bPass = async ({commit}, newPassData) => {
  try {
    const {data: result} = await authClient.post('/reset-password/number-nomina', newPassData)
    const {data, message, error} = result.result
    return {data, message, error}

  } catch (ex) {
      const error_http_message = errorHttpMessage(ex);
      return {message: error_http_message.message, data: ex, error: true}
  }
}