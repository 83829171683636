import patientService from '@/services/patientService';
import psychologistConsultationService from '@/services/psychologistConsultationService';
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const getPatientById = async ({commit}, id) => {
  try {
    const {data: response} = await patientService.getPatientById(id);
    return response.result;
  } catch (ex) {
    // const error_http_message = errorHttpMessage(ex);
    return {
      // message: error_http_message?.message,
      // error_keys: error_http_message?.error_keys,
      // data: ex?.response?.data,
      error: true,
    };
  }
}

export const setPsychologyEvaluation = async ({commit}, info) => {
  try {
    debugger;
    const {data: response} = await psychologistConsultationService.setPsychologyEvaluation(info);
    return response.result;
  } catch (ex) {
    // const error_http_message = errorHttpMessage(ex);
    return {
      // message: error_http_message?.message,
      // error_keys: error_http_message?.error_keys,
      // data: ex?.response?.data,
      error: true,
    };
  }
}