import Swal from 'sweetalert2'

const fire = (config) => {
    // Overwritting swal config params
    if (!config.customClass) {
        config.customClass = 'sherpa-alert'
    }

    if(!config.confirmButtonColor) {
        config.confirmButtonColor = '#0286C1'
    }

    config.icon = ''

    return Swal.fire({
        ...config,
        heightAuto: false
    })
}

const close = () => {
    return Swal.close()
}

const isLoading = () => {
    return Swal.isLoading()
}

export default {
    fire,
    close,
    isLoading
}