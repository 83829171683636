import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: "interviewer",
  component: () => import(/* webpackChunkName: "users" */ '@/layouts/PrincipalLayout.vue'),
  children: [
    {
      path: "",
      name: "interviewer-index",
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.interviewer',
            isActive: true
          }
        ]
      },
      beforeEnter: [isVerifyTokenGuard],
      component: () => import(/* webpackChunkName: "demo-childres" */ "../views/Index.vue"),
    },
    {
      path: "interviewer-psicologists/:id",
      name: "interviewer-psychologist",
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.interviewer-details',
            isActive: true
          }
        ]
      },
      beforeEnter: [isVerifyTokenGuard],
      component: () =>
        import(
          /* webpackChunkName: "interviewer-psychologis" */ "../views/Interviewer.vue"
        ),
    },
  ],
};
