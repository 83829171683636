import psychologyConsultationClient from '@/axiosClients/psychologyConsultationClient';

const getResume = (data) => {
    return psychologyConsultationClient.post(`/checkout`, data);
}

const setPaymentProcess = (data) => {
    return psychologyConsultationClient.post(`/checkout/set-payment-processed`, data);
}

const getScheduledSessions = () => {
    return psychologyConsultationClient.get(`/scheduled-sessions/waiting-room`)
}

const setPsychologyEvaluation = (data) => psychologyConsultationClient.post(`/psychology-evaluation`, data);

const updatePsychologistConsultation = (data) => {
    return psychologyConsultationClient.post(`/change-date/${data.consultationId}`, data);
}

const getCards = () => {
    return psychologyConsultationClient.get(`/checkout/cards`)
}

const setCardToCustomer = (data) => {
    return psychologyConsultationClient.post(`/checkout/attach-payment-method`, data)
}

const deleteCard = (cardId) => {
    return psychologyConsultationClient.delete(`/checkout/card/${cardId}`)
}

const addMinutesToPenddingPayment = (data) => {
    return psychologyConsultationClient.post(`/checkout/add-minutes-payment-pending`, data)
}

const getPatientsOfPsychologist = (userId) => {
    return psychologyConsultationClient.get(`/consultations/billing/get-patients-of-psychologist/${userId}`);
}

const getExcelConsultationForPay = (data) => {
    return psychologyConsultationClient.get(`/consultations/for-pay/export${data.filtersString}`, {
        responseType: 'blob'
    });
}

const setPayPsychologistSessions = (sessions) => {
    return psychologyConsultationClient.post(`/consultations/set-paid`, sessions)
}

const generateCFDISessions = (sessions) => {
    return psychologyConsultationClient.post(`/consultations/generateCFDI`, sessions)
}

const getXmlOrPdfDownload = (data) => {
    return psychologyConsultationClient.get(`/consultations/download/${data.type}/invoice/${data.invoiceId}`, {
        responseType: 'blob'
    });
}

const checkoutRecurrency = (data) => {
    return psychologyConsultationClient.post(`/checkout/recurrency`, data)

}
const setDefaultPaymentCard = (data) => {
    return psychologyConsultationClient.post(`/checkout/default-card/${data.userId}`, {card_id: data.cardId})
}

const generateCharge = (data) => {
    return psychologyConsultationClient.post(`/checkout/generate-charge`, data)
}

const applyDiscountCode = (data) => {
    return psychologyConsultationClient.post(`/checkout/apply-discount-code`, data)
}

const calculateTotalApplyCardMimente = (data) => {
    return psychologyConsultationClient.post(`/checkout/calculate-total/apply-discount-card`, data)
}

const cancelConsultation = (data) => {
    return psychologyConsultationClient.post(`/consultations/cancel-session`, data)
}

const getExcelCanceledSessions = (data) => {
    return psychologyConsultationClient.get(`/consultations/cancelled/export`, {
        responseType: 'blob'
    });
}

const updatePsychologistEvaluationNotes = (data) => psychologyConsultationClient.post(`/psychology-evaluation/${data.noteId}`, data);

const addPatientToWaitingList = (data) => psychologyConsultationClient.post(`/waiting-list/add-user`, data);

const removePatientFromWaitingList = (data) => psychologyConsultationClient.post(`/waiting-list/remove-user`, data);



export default {
    deleteCard,
    setCardToCustomer,
    getCards,
    getResume,
    setPaymentProcess,
    getScheduledSessions,
    setPsychologyEvaluation,
    updatePsychologistConsultation,
    addMinutesToPenddingPayment,
    getPatientsOfPsychologist,
    getExcelConsultationForPay,
    setPayPsychologistSessions,
    generateCFDISessions,
    getXmlOrPdfDownload,
    checkoutRecurrency,
    setDefaultPaymentCard,
    generateCharge,
    applyDiscountCode,
    calculateTotalApplyCardMimente,
    cancelConsultation,
    getExcelCanceledSessions,
    updatePsychologistEvaluationNotes,
    addPatientToWaitingList,
    removePatientFromWaitingList
}
