import axios from "axios";
import store from "@/store";

const configurationClient = axios.create({
  baseURL: process.env.VUE_APP_PSYCHOLOGY_CONSULTATION_URL
});

configurationClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters["auth/getToken"]}`;  
  config.headers.lang = store.getters["getLang"];
  return config;
});

export default configurationClient;