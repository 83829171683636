import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import hasAccessGuard from "@/guards/hasTokenGuard";

export default {
    name: 'schedule',
    component: () => import(/* webpackChunkName: "principal" */ '@/layouts/PrincipalLayout.vue'),
    children: [
        {
            path: ':psychologiId',
            name: 'schedule-appointment',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "schedule-appointment" */ '@/modules/appointments/views/ScheduleAppoitment.vue')
        },
        {
            path: 'payment',
            name: 'schedule-appointment-payment',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "schedule-appointment-payment" */ '@/modules/appointments/views/Payment.vue')
        },
        {
            path: 'payment-confirmation',
            name: 'schedule-appointment-payment-confirmation',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "schedule-appointment-payment" */ '@/modules/appointments/views/PaymentConfirmation.vue')
        },
        {
            path: 'recurring/:psId/:patientUserId/:roomName/:consultationId',
            name: 'schedule-recurring-appointment',
            // beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "schedule-appointment-payment" */ '@/modules/appointments/views/ScheduleRecurringAppoitment.vue')
        },
        {
            path: 'psychology/recurring/:psId/:patientUserId',
            name: 'psychologist-schedule-recurring-appointment',
            // beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "schedule-appointment-payment" */ '@/modules/appointments/views/ScheduleRecurringAppoitment.vue')
        },
        {
            path: 'admin/recurring/:psId/:patientUserId',
            name: 'admin-schedule-recurring-appointment',
            // beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "schedule-appointment-payment" */ '@/modules/appointments/views/ScheduleRecurringAppoitment.vue')
        }
    ]    
}