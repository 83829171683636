import usersClient from "@/axiosClients/usersClient";
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const createUser = async ({commit}, data) => {
  try {
    const {data: result} = await usersClient.post('/create', data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex)
    return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
  }
}

export const getUserDataById = async ({commit}, user_id) => {
  try {
    const {data: result} = await usersClient.get(`/show/${user_id}`)
    const {data, message, error} = result.result
    return {data, message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const updateUserData = async ({commit}, userData) => {
  try{
      const { data: result } = await usersClient.post(`/${userData.user_id}/psychologist`, userData.data)
      const { data, message, error } = result.result
      return {data,message, error}        
  } catch(ex) {                     
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  } 
}

export const updatePhoto = async ({commit}, dataPhoto) => {
  try{
    const { data: result } = await usersClient.post(`/${dataPhoto.user_id}/psychologist/update-photo`, dataPhoto.photo)
    const { data, message, error } = result.result
    return {data,message, error}        
  } catch(ex) {                     
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  } 
}

export const sendUserAccess = async ({commit}, psychologist_id) => {
  try{
    const { data: result } = await usersClient.post(`/send-email-access/${psychologist_id}`)
    const { data, message, error } = result.result
    return {data,message, error}        
  } catch(ex) {                     
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  } 
}

export const delteMedaFile = async ({commit}, fileData) => {
  try{
    const { data: result } = await usersClient.delete(`my/profile/file/${fileData.id}/${fileData.type}/${fileData.user_id}`)
    const { data, message, error } = result.result
    return {data,message, error}        
  } catch(ex) {                     
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  } 
}