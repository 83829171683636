import psychologyClient from "@/axiosClients/psychologyClient.js";
import psychologyConsultationClient from "@/axiosClients/psychologyConsultationClient";
import {errorHttpMessage} from "@/utils/errorDecodeUtil";

export const postInterviewerPsychology = async (
    {commit},
    {id, psychologist_recommendation, interview_notes}
) => {
    try {
        const {data: result} = await psychologyClient.post(`/interviews/` + id, {
            psychologist_recommendation,
            interview_notes,
        });
        const {message, error} = result;
        return {message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const subsequentEvaluation = async ({commit}, dataForm) => {
    try {
        const {data: result} = await psychologyConsultationClient.post(
            `/subsequent-evaluation`,
            dataForm
        );
        const {message, error, data} = result;
        return {message, error, data};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            // data: ex.response.data,
            error: true,
        };
    }
};

export const firstEvaluation = async ({commit}, dataForm) => {

    try {
        const {data: result} = await psychologyConsultationClient.post(
            `/first-evaluation`,
            dataForm
        );
        const {message, error, data} = result;
        return {message, error, data};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
