import psychologyClient from "@/axiosClients/psychologyClient.js";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const getMatching = async ({ commit }, { reasons, partner, timezone, patient_id, ids_psychologits_ignore }) => {
  try {
    const { data: result } = await psychologyClient.get(`/matching`, {
      params: {
        reason_consultations: reasons,
        reason_consultation_items: partner,
        timezone,
        patient_id: patient_id,
        ids_psychologits_ignore
      }, 
    });
    commit('setPsychologits', result.result.data);
    return result.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const getPsychology = async ({ commit }, { id }) => {
  try {
    const { data: result } = await psychologyClient.get(`${id}`);
    return result.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
};
