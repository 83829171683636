import psychologistConsultationService from "@/services/psychologistConsultationService";
import psychologistService from '@/services/psychologistService';
import patientService from "@/services/patientService";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const getScheduledSessions = async ({commit}) => {
  try {
    const {data: response} = await psychologistConsultationService.getScheduledSessions();
    return response.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}

export const getMachingV2 = async ({commit}, options) => {
  try {
    const {data} = await psychologistService.getMachingV2(options);
    commit('psycologistsOptions/setPsychologits', {...data.result.data }, {root: true});
    return data.result;
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}

export const getPsychologistById = async ({commit}, options) => {
  try {
    // const { data:datesData } = await psychologistService.getAvailableDates(options);
    const { data } = await psychologistService.getPsychologistById(options.id);
    const psychologist = {...data.result.data};
    // psychologist.dates_availables = datesData.result.data;
    commit('psycologistsOptions/setPsychologits', {matching: [psychologist] }, {root: true});
    return data.result
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}

export const acceptInformedConsent = async ({commit}, patient_id) => {
  try {
    const {data: response} = await patientService.acceptInformedConsent(patient_id) ;
    return response.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}

export const generateCharge = async ({commit}, sessionData) => {
  try {
    const {data: response} = await psychologistConsultationService.generateCharge(sessionData) ;
    return response.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message?.message,
      error_keys: error_http_message?.error_keys,
      data: ex?.response?.data,
      error: true,
    };
  }
}


