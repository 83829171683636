import patientClient from '@/axiosClients/patientClient';
import psychologyConsultationClient from "@/axiosClients/psychologyConsultationClient";


const getPatientById = (id) => patientClient.get(`/patient/${id}`);

const acceptInformedConsent = (patientId) => {
    return patientClient.post(`/patient/accept-informed-consent/${patientId}`)
}
const updateContactInformation = (info) => {
    return patientClient.post(`/update-contact-information`, info);
}

const getExcelUserInformation = () => {
    return patientClient.get(`/patient/report`, {
        responseType: 'blob'
    });
}

export default {
    getPatientById,
    acceptInformedConsent,
    updateContactInformation,
    getExcelUserInformation
}
