export const getDataStoreByKey = (state) => (key) => state[key];

export const getMetricsUUID = (state) => state.metrics.uuid;

export const getToken = (state) => state.auth.token;

export const getLoggedUser = (state) => state.auth.user;

export const isUserB2B = (state) => state.auth.user ? !!state.auth.user.number_nomina : false;

export const is100User = (state) => state.auth.user ? state.auth.user.is100 : false;
// export const is100User = (state) => true;

export const isExpired = (state) => {            
    const diff = debugDiffTimeToken(state)
    //The expires limit token is after 60 minutes
    if (diff <= 0) {
        return true
    }
    return false
}

export const debugDiffTimeToken = (state) => {        

    const expired = getExpires(state)
    const d1 = new Date(expired * 1000)
    const d2 = new Date();

    const diff = (d1.getTime() - d2.getTime()) / (1000 * 60)    
    return diff
}

export const getExpires = (state) => {    
    try {
        const token = getToken(state);
        const jwt = parseJwt(token);

        return jwt.exp || 0
    } catch (ex) {
        return 0
    }
}

export const getRememberUser = (state) => {
    return sessionStorage.getItem('rememberUser') || ""
}

export const setRememberUser = (rememberUser) => {
    return sessionStorage.setItem('rememberUser', rememberUser)
}

export const removeRememberUser = (rememberUser) => {
    return sessionStorage.removeItem('rememberUser')
}

function parseJwt(token) {    
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const getIsFamilyRoute = (state) => {
    return state.isFamilyRoute;
}

export const getBrowserTimezone = (state) => {
    let timezone = '';
    switch(state.auth.timezone?.toLowerCase()) {
        case 'etc/gmt+6': 
            timezone =  'America/Monterrey';
            break;
        case undefined:
            timezone = 'America/Monterrey';            
            break;
        default:             
            timezone = state.auth.timezone;            
            break;
    }
    return timezone;
}