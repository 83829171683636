import store from '@/store'

const landingGuard = (to, from, next) => {    
    const token = store.getters["auth/getToken"]
    const isExpired = store.getters['auth/isExpired']
    const loggedUser = store.getters['auth/getLoggedUser']

    if (!isExpired && token) {        
        let routeName = '';        
        switch(loggedUser.role_id) {
            case 1:
                routeName = 'scheduled-sessions';
                break;
            case 2:
                routeName = 'waiting-room-index';
                break;
            case 3:
                routeName = 'interviewer-index';
                break;  
            case 4:
                routeName = 'users-index';
                break;
        }
        next({name: routeName})

    } else {   
        store.commit('auth/logout')     
        next();        
    }

}

export default landingGuard
