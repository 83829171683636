import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: "waiting-room",
    component: () => import(/* webpackChunkName: "users" */ '@/layouts/PrincipalLayout.vue'),
    children: [
        {
            path: '',
            name: 'waiting-room-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [{
                    transKey: 'breadcrumbs.waiting-room-index',
                    isActive: true
                }]
            },
            component: () => import( /* webpackChunkName: "users-index" */ '@/modules/waiting-room/views/Index.vue'),
            props: true
            //props: {roomName: "39fd6428-5560-47cc-ad92-a7370e55fd65"}
        },
        {
            path: 'life-line',
            name: 'life-line-index',
            // beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [{
                    transKey: 'breadcrumbs.life-line',
                    isActive: true
                }]
            },
            component: () => import( /* webpackChunkName: "users-index" */ '@/modules/waiting-room/views/LifeLine.vue')
        }
    ]
}
