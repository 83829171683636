import {createStore} from 'vuex'
import auth from '@/modules/principal/store'
import interviewer from "@/modules/interviewer/store";
import psycologistsOptions from '@/modules/psycologists-options/store'
import psycologistsConfigurations from '@/modules/psycologists/configuration/store'
import psycologistsPatient from '@/modules/psycologists/patients/store';
import users from '@/modules/users/store'
import profileClient from '@/modules/patients/profile/store'
import profilePsychologist from '@/modules/psycologists/profile/store'
import appointments from '@/modules/appointments/store';
import waitingRoom from '@/modules/waiting-room/store';
import videocall from '@/modules/videocall/store';
import privacy from '@/modules/privacy/store';
import psychologistInvoicing from '@/modules/psycologists/invoicing/store';
import customers from '@/modules/admin/customers/store';
import patients from '@/modules/patients/store'
import discountCodes from '@/modules/admin/discount-codes/store';
import cardsMimente from '@/modules/admin/cards-mimente/store';

import authClient from '@/axiosClients/authClient'
import { errorHttpMessage } from '@/utils/errorDecodeUtil'
import {removeMenuPermission} from '@/utils/permissionsUtil'


export default createStore({
    state: {
        lang: sessionStorage.getItem("lang") || "es",
        axiosOperationDomain: "",
        globalToastData: {},
        generalIsLoading: false,
        generalLoadingText: null,
        generalLoadingOpacity: 1,
        videocallRoomName: '',
        dyteToken: '',
        startVideoCall: false,
        menus: [],
        psyMatchesFound: null
    },
    getters: {
        getLang: (state) => state.lang,
        getAxiosOperationDomain: (state) => state.axiosOperationDomain,
        getGlobalToastData: (state) => ({...state.globalToastData}),
        getGeneralIsLoading: (state) => state.generalIsLoading,
        getGeneralLoadingText: (state) => state.generalLoadingText,
        getGeneralLoadingOpacity: (state) => state.generalLoadingOpacity,
        getVideocallRoomName: (state) => state.videocallRoomName,
        getDyteToken: (state) => state.dyteToken,
        getStartVideoCall: (state) => state.startVideoCall,
        getStartVideoCallDyte: (state) => state.startVideoCallDyte,
        getMenus: (state) => [...state.menus],
        getPsyMatchesFound: (state) => state.psyMatchesFound
    },
    mutations: {
        setLang: (state, lang) => {
            state.lang = lang;
            sessionStorage.setItem("lang", lang);
        },
        setGlobalToastData: (state, data) => {
            state.globalToastData = {...data};
            const toastLiveExample = document.getElementById("liveToast");
            const toast = new bootstrap.Toast(toastLiveExample);
            toast.show();
        },
        setGeneralIsLoading: (state, value) => {
            state.generalIsLoading = value;
        },
        setGeneralLoadingText: (state, value) => {
            state.generalLoadingText = value;
        },
        setVideocallRoomName: (state, value) => {
            state.videocallRoomName = value
        },
        setDyteToken: (state, value) => {
            state.dyteToken = value
        },
        setStartVideoCall: (state, value) => {
            state.startVideoCall = value
        },
        setStartVideoCallDyte: (state, value) => {
            state.startVideoCallDyte = value
        },
        setMenus: (state, menus) => {
            state.menus = menus
        },

        setPsyMatchesFound: (state, value) => {
            state.psyMatchesFound = value
        },

        reloadMenus: (state) => {
            state.menus.forEach((a, b, c) => {
                a.visible = true
                removeMenuPermission(a)
            })
        },
    },
    actions: {
        loadMenus: async ({commit}) => {
            try {
                const {data} = await authClient.get('/menus-available')
                commit('setMenus', data)
            } catch (ex) {
                const error_http_message = errorHttpMessage(ex);
                return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
            }
        },
    },
    modules: {
        auth,
        psycologistsOptions,
        psycologistsConfigurations,
        interviewer,
        users,
        appointments,
        waitingRoom,
        psycologistsPatient,
        profileClient,
        profilePsychologist,
        videocall,
        privacy,
        customers,
        patients,
        discountCodes,
        cardsMimente
    },
});
